const Utils = require('../utils');
const DivSlot = require('../divSlot');
const AdserverBase = require('../adserverBase');
const SmartAuction = require('./smartAuction');

const SAS_START = 'sas_';

class SmartSlot extends DivSlot {
	setTargeting(k, v) {
		this.target = { ...this.target, [k]: v };
	}
}

/**
 * "Extract" format info from div-id, for example "sas_123_4" is 'formatId' 123 with 'instanceNum' 4, etc.
 */
const fmtInfoFromPath = (divId, path) => {
	const fmtStr = path || divId;
	const prefix = path ? '' : SAS_START;
	const formatId = parseInt(fmtStr.slice(prefix.length), 10);
	const idStart = `${prefix}${formatId}`;
	let instanceNum;
	if (fmtStr[idStart.length] === '_') {
		instanceNum = parseInt(fmtStr.slice(idStart.length + 1), 10);
	}
	const instancePostfix = instanceNum ? `_${instanceNum}` : '';
	const tagId = path ? divId : `${idStart}${instancePostfix}`;
	if (formatId && (path || tagId === divId)) {
		let baseTagId;
		if (path) {
			baseTagId = divId.slice(0, divId.length - instancePostfix.length);
			if (instancePostfix && divId.slice(-instancePostfix.length) !== instancePostfix) {
				/**
				 * It's not possible to use e.g. <div id = "hello" data-ad-unit-id="1234_2"> as Smart
				 * requires that the instance number is last in the id, like this:
				 * <div id = "hello_2" data-ad-unit-id="1234_2">
				 */
				console.error(`Incompatible divId '${divId}' and path '${path}'`);
				return null;
			}
		} else {
			baseTagId = idStart;
		}
		return {
			formatId,
			fmtInstanceId: `${formatId}${instancePostfix}`,
			instanceNum,
			tagId,
			baseTagId,
		};
	}
	return null;
};

const withSas = ((fn) => {
	window.sas = window.sas || {};
	const { sas } = window;
	sas.cmd = sas.cmd || [];
	sas.cmd.push(() => fn(sas));
});

// sasRender() called but not sendAdserverRequest(), key is 'fmtInstanceId' (like "123_4")
const renderTagsWaitingCall = {};

// sendAdserverRequest() called but not sasRender(), key is 'fmtInstanceId' (like "123_4")
const callTagsWaitingRender = {};

// Callbacks for when sasRender() is called, key is 'tagId' (like "sas_123_4")
const waitingRenderCallbacks = {};

const sasRenderFn = (tagId) => {
	const { sas, relevantDigital } = window;
	const renderFn = sas.render.orgRenderFn || sas.render; // 'orgRenderFn' only exists on test-setups
	if (tagId) {
		relevantDigital.registerRenderedDivId(tagId);
		const cb = waitingRenderCallbacks[tagId];
		if (cb) {
			cb({ tagId });
		}
		renderFn.call(sas, tagId);
	} else {
		renderFn.call(sas);
	}
};

class SmartAdserver extends AdserverBase {
	constructor(...args) {
		super(...args);

		// Used to queue reloads of the same main auction in case they happen in quick succession
		// (and/or if Smart is very slow..)
		this.secondaryAuctionWaiters = Utils.waitQueue();
	}

	init(__, doneCb) {
		withSas((sas) => {
			this.sas = sas;
			doneCb();
		});
	}

	getAmazonIntegrationInfo() {
		return {
			adServerName: 'sas',
			useSetDisplayBids: false,
			requiresRenderHook: true,
		};
	}

	adUnitPathsFromUnitInternal({ sasFmtIds }) {
		return sasFmtIds.map((fmtId) => fmtId.toString());
	}

	getType() {
		return 'smart';
	}

	get floorProps() {
		return { canSetFloors: true };
	}

	updateAdUnitPath(dstAdUnit, adUnitPath) {
		const p = adUnitPath.indexOf(SAS_START) === 0 ? adUnitPath.slice(SAS_START.length) : adUnitPath;
		const sasFmtId = parseInt(p, 10);
		if (Number.isNaN(sasFmtId)) {
			return false;
		}
		dstAdUnit.sasFmtIds = [sasFmtId];
		return true;
	}

	static staticInit() {
		// "Expose" 'relevantDigital.sasRender()'
		window.relevantDigital.sasRender = SmartAdserver.sasRender;
	}

	// Examples of val: 54606, "54606", "54606_2", "sas_54606" , "sas_54606_2", "top-banner"
	static sasRender(val) {
		const withSasStr = `${SAS_START}${val}`;
		const tagId = fmtInfoFromPath(withSasStr) ? withSasStr : val;
		withSas(() => {
			if (callTagsWaitingRender[tagId]) {
				delete callTagsWaitingRender[tagId];
				sasRenderFn(tagId);
			} else {
				renderTagsWaitingCall[tagId] = true;
			}
		});
	}

	getCodeStart(path) {
		return `${SAS_START}${path}`;
	}

	// Called when setting up a reload auction
	finalizeReloadSettings(settings) {
		settings.sasOptions = {
			...settings.sasOptions,
			autoRender: true,
		};
	}

	finalizeParams(params) {
		const targeting = [];
		Utils.entries(this.getGlobalTargeting()).forEach(([key, value]) => {
			targeting.push(`${key}=${value}`);
		});

		if (!targeting.length) {
			return params;
		}

		const { target } = params;
		const addTargets = [...targeting, target].filter((t) => t).join(';');

		const newParams = {
			...params,
			target: addTargets,
		};
		return newParams;
	}

	finalizeLazyLoadSettings(settings) {
		this.finalizeReloadSettings(settings);
	}

	/** We're "automatically" creating "slots" for every "sas_NNN" div we find on the page */
	getSlots() {
		const tagIds = [].slice.call(document.querySelectorAll(`[id^="${SAS_START}"]`)).map(({ id }) => id);
		const { preloadSlots } = this.auction.sasOptions || {};
		if (preloadSlots) {
			this.auction.adUnits.forEach(({ sasFmtIds }) => {
				sasFmtIds.forEach((sasFmtId) => {
					const tagId = `${SAS_START}${sasFmtId}`;
					if (tagIds.indexOf(tagId) < 0) {
						tagIds.push(tagId);
					}
				});
			});
		}
		tagIds.forEach((divId) => this.createSlot({ divId }));
		return SmartSlot.list();
	}

	createSlot({ divId, path }) {
		const info = fmtInfoFromPath(divId, path);
		if (!info) {
			return null;
		}
		return SmartSlot.getOrCreateSlot(info.tagId, info.formatId.toString(), { ...info, divId });
	}

	createDivId(path) {
		let id = path;
		if (id.indexOf(SAS_START) !== 0) {
			id = `${SAS_START}${path}`;
		}
		const info = fmtInfoFromPath(id);
		if (!info) {
			return null;
		}
		return info.tagId;
	}

	oneTimePageSetup({ auction }) {
		const { networkid, domain } = this;
		this.sas.setup({
			networkid,
			domain,
			async: true,
			renderMode: 2,
			...(auction.sasOptions || {}).setup,
		});
	}

	/** To support the 'panicDisable' option for bypassing Smart ad requests upon Smart-problems */
	triggerFakeOnLoads(slots) {
		slots.forEach((slot) => {
			this.onLoad({
				formatId: slot.formatId,
				tagId: slot.getSlotElementId(),
				hasAd: false,
			});
		});
	}

	/** onLoad() Callback from sas.call(). This function is only used by the SmartAdserver that is calling sas.call()
	 * while reload-auctions (this.secondaryAuction) will be called when the "main" SmartAuction (this.mySmartAuction)
	 * already got a corresponding onLoad callback.
	 */
	onLoad(param) {
		if (!this.mySmartAuction.addResponse(param) && this.secondaryAuction) {
			this.secondaryAuction.addResponse(param, true);
			if (this.secondaryAuction.isSmartLoadFinished()) {
				this.secondaryAuction = null;
				this.secondaryAuctionWaiters.setAvailable(); // Reload auction done, and next can run (if any)
			}
		}
	}

	/** Only called from the "main" auction (not during reloads) */
	sasCall({
		requestAuction, smartAuction, formats, slots,
	}) {
		const { sas } = this;
		const { sasOptions } = requestAuction;
		const {
			siteId, pageId, callParams, callOptions, panicDisable, stdCall,
		} = sasOptions || {};

		const onRender = (param) => {
			if (!smartAuction.addRenderCall(param) && this.secondaryAuction) {
				this.secondaryAuction.addRenderCall(param); // forward to reload auction
			}
		};

		slots.forEach((slot) => {
			waitingRenderCallbacks[slot.getSlotElementId()] = onRender;
		});

		if (panicDisable) {
			// use setTimeout to avoid some complexity if this.onLoad is called at this stage.
			setTimeout(() => this.triggerFakeOnLoads(slots));
		} else if (stdCall) {
			stdCall.forEach((obj) => sas.call('std', this.finalizeParams(obj), {
				onLoad: (param) => this.onLoad(param),
			}));
		} else { // onecall, split it up in case the same format occurs multiple times
			let seen = {};
			let chunk = [];
			const chunks = [];
			formats.forEach((fmt) => {
				if (seen[fmt.id]) {
					chunks.push(chunk);
					chunk = [];
					seen = {};
				}
				seen[fmt.id] = true;
				chunk.push(fmt);
			});
			chunks.push(chunk);
			chunks.forEach((fmtChunk) => sas.call('onecall', this.finalizeParams({
				siteId,
				pageId,
				formats: fmtChunk,
				target: '',
				...callParams,
			}), {
				onLoad: (param) => this.onLoad(param),
				// Can't decide if getAdContent should be true/false as performance seems to vary a lot, might be
				// good with some A/B testing of that
				// getAdContent: true,
				...callOptions,
			}));
		}
	}

	sendAdserverRequest({ requestAuction, usedUnitDatas }) {
		const { sasOptions, mainAuction } = requestAuction;
		const { sas } = this;
		const fmtMap = {}; // formats used (e.g. "123_4" and "123_5" is the same format 123)
		const slots = usedUnitDatas.map(({ slot }) => slot);
		const wasWaiting = []; // "formatInstanceId" we already got sasRender() calls for
		usedUnitDatas.forEach(({ slot, adUnit }) => {
			const { formatId, tagId, baseTagId, target } = slot;
			fmtMap[baseTagId] = { formatId, target, floor: adUnit.adsFloor };
			if (!mainAuction) { // This is *not* a reload
				if (renderTagsWaitingCall[tagId] || renderTagsWaitingCall[formatId]) {
					wasWaiting.push(tagId);
					delete renderTagsWaitingCall[tagId];
				}
				callTagsWaitingRender[tagId] = true;
			}
		});
		const { autoRender, panicDisable } = sasOptions || {};
		const toRender = Utils.uniq([
			...wasWaiting,
			...(autoRender ? slots.map((s) => s.tagId) : []), // autoRender => *we* call sasRender()
		]);
		const formats = Object.entries(fmtMap).map(([tagId, { formatId, target, floor }]) => ({
			id: parseInt(formatId, 10),
			tagId,
			target: Object.entries(target ?? {})
				.filter(([, value]) => value)
				.map(([key, value]) => `${key}=${value}`)
				.join(';'),
			...(typeof floor === 'number' && { overriddenBidfloor: floor }),
		})); // formats on sas.call() format
		const smartAuction = new SmartAuction({ auction: requestAuction });
		this.mySmartAuction = smartAuction;
		if (mainAuction) { // This is a reload
			const mainAdserver = Utils.find(mainAuction.adservers, (ads) => ads instanceof SmartAdserver);
			// use wait() to not call sas.refresh() in "parallel" with previous reload
			mainAdserver.secondaryAuctionWaiters.wait(() => {
				mainAdserver.secondaryAuction = smartAuction;
				smartAuction.initSlotInstances({
					sas, usedUnitDatas, isReload: true,
				});
				if (panicDisable) { // Skip Smart ad-request
					slots.forEach((slot) => {
						const elm = document.getElementById(slot.getSlotElementId());
						if (elm) {
							elm.innerHTML = ''; // Manually clear ad-div
						}
					});
					mainAdserver.triggerFakeOnLoads(slots);
				} else {
					toRender.forEach((tagId) => sas.refresh(tagId));
				}
			});
		} else {
			smartAuction.initSlotInstances({ sas, usedUnitDatas });
			this.sasCall({
				requestAuction,
				smartAuction,
				formats,
				slots,
			});
			toRender.forEach((tagId) => SmartAdserver.sasRender(tagId));
			sasRenderFn();
		}
	}
}

module.exports = SmartAdserver;
