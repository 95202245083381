const Utils = require('../utils');
const HbmUtils = require('../hbmUtils');

/** Internal representation of an ad-unit, used only by SmartAuction */
class AuctionSlot {
	constructor(settings) {
		Utils.assign(this, settings);
	}

	/** The DivSlot instance */
	slot() {
		return this.unitData.slot;
	}

	/** Example '/1234/top_banner' */
	gamPassbackPath() {
		return this.adUnit().gamPassbackPath;
	}

	adUnit() {
		return this.unitData.adUnit;
	}

	tagId() {
		return this.slot().getSlotElementId();
	}

	elm() {
		return document.getElementById(this.tagId());
	}

	hasAd() {
		return !!(this.bid || this.hasSmartAd || this.hasGamAd);
	}

	adSource() {
		if (this.hasSmartAd) {
			return 'smart';
		}
		if (this.bid) {
			return 'prebid';
		}
		if (this.hasGamAd) {
			return 'google';
		}
		return null;
	}

	// Creates an empty iframe for Prebid to render the winning bid
	renderPrebid() {
		HbmUtils.renderBid({
			auction: this.smartAuction.auction,
			bid: this.bid,
			adDiv: this.elm(),
		});
	}

	onLoaded() {
		const { onLoad } = this.smartAuction.auction.sasOptions || {};
		if (onLoad) {
			const slot = this.slot();
			onLoad({
				formatId: slot.formatId,
				tagId: slot.getSlotElementId(),
				hasAd: this.hasAd(),
				source: this.adSource(),
			});
		}
	}
}

let gptLoaded;
const gamPathToAuctionSlot = {};
let gamListenerInitialized;

/** Manages an auction in Smart after SmartAdserver.sendAdserverRequest() has been called.
 * This involves calling the GAM-passbacks and rendering Prebid-ads when we're getting noAd.
 */
class SmartAuction {
	constructor({ auction }) {
		Utils.assign(this, {
			auction,
			auctionSlots: [],
		});
	}

	addSlotInstance(settings) {
		this.auctionSlots.push(new AuctionSlot({
			...settings,
			smartAuction: this,
		}));
	}

	onSmartCallDone(chunk) {
		// SmartSlots wihtout Prebid/Smart ads but with a GAM passback (that's the ones we should request from GAM)
		const gamSlots = [];
		chunk.forEach((s) => {
			if (!s.hasAd() && s.gamPassbackPath()) {
				gamSlots.push(s);
			} else {
				s.onLoaded();
			}
		});
		if (!gamSlots.length) {
			return;
		}
		if (!this.auction.noGpt && !gptLoaded) { // Manually load gpt.js
			gptLoaded = true;
			Utils.loadScript('//securepubads.g.doubleclick.net/tag/js/gpt.js');
		}
		Utils.withGoogle((googletag) => {
			const tagIdMap = {};
			if (!googletag.pubadsReady) { // Setup some standard stuff
				googletag.pubads().disableInitialLoad();
				googletag.pubads().enableSingleRequest();
				googletag.enableServices();
			}
			if (!gamListenerInitialized) {
				gamListenerInitialized = true;
				googletag.pubads().addEventListener('slotRenderEnded', (ev) => {
					const auctionSlot = gamPathToAuctionSlot[ev.slot.getAdUnitPath()];
					if (auctionSlot) {
						auctionSlot.hasGamAd = !ev.isEmpty;
						auctionSlot.onLoaded();
					}
				});
			}
			gamSlots.forEach((auctionSlot) => {
				const divId = auctionSlot.tagId();
				let gamSlot = Utils.find(googletag.pubads().getSlots(), (s) => s.getSlotElementId() === divId);
				if (!gamSlot) { // else => we're probably reloading
					gamSlot = googletag.defineSlot(
						Utils.injectMcmPart(auctionSlot.gamPassbackPath(), this.auction.pbRequester.mcmChildNwid),
						auctionSlot.adUnit().getPrebidSizes(),
						auctionSlot.tagId(),
					);
					if (gamSlot) {
						gamSlot.addService(googletag.pubads());
					}
				}
				if (gamSlot) {
					gamPathToAuctionSlot[gamSlot.getAdUnitPath()] = auctionSlot;
				}
				tagIdMap[auctionSlot.tagId()] = true;
			});
			const refreshSlots = googletag.pubads().getSlots().filter((s) => tagIdMap[s.getSlotElementId()]);
			googletag.pubads().refresh(refreshSlots); // Request passbacks
		});
	}

	initSlotInstances({
		sas, usedUnitDatas, isReload,
	}) {
		usedUnitDatas.forEach((unitData) => {
			const { slot } = unitData;
			const bid = unitData.getHighestBid();
			if (bid) {
				sas.setHeaderBiddingWinner(slot.tagId, bid);
			}
			this.addSlotInstance({ unitData, bid, renderCalled: isReload });
		});
	}

	getAuctionSlot(tagId) {
		return Utils.find(this.auctionSlots, (s) => s.tagId() === tagId);
	}

	isSmartLoadFinished() {
		return !Utils.find(this.auctionSlots, (a) => !a.chunkDone);
	}

	addResponse({ tagId, hasAd }, isSecondaryAuction) {
		const auctionSlot = this.getAuctionSlot(tagId);
		if (!auctionSlot || auctionSlot.gotResponse) {
			return false; // We already got response, this is probably for a reload
		}
		auctionSlot.hasSmartAd = hasAd;
		if (hasAd && isSecondaryAuction) {
			// for some god-damn-reason 'hasAd' can't be trusted when reloading, we need a manual history check
			const history = window.sas.events.history();
			for (let i = history.length - 1; i >= 0; i -= 1) {
				const ev = history[i];
				if ((ev.data || {}).tagId === tagId) {
					if (ev.eventName === 'render' || ev.eventName === 'noad') {
						auctionSlot.hasSmartAd = ev.eventName !== 'noad';
						break;
					}
				}
			}
		}
		auctionSlot.gotResponse = true;
		if (!auctionSlot.hasSmartAd && auctionSlot.bid) { // No ad from Smart but a winning bid, let's render it
			auctionSlot.renderPrebid();
		}
		const waitingChunkDone = this.auctionSlots.filter((a) => a.renderCalled && !a.chunkDone);
		// Check if we have a "chunk" of ad units that
		//    1) render was requested for
		//    2) we got a Smart-response for
		// This "chunk" will be the ad units we're doing a single request call to GAM for. (at least some of them)
		if (waitingChunkDone.length && !Utils.find(waitingChunkDone, (a) => !a.gotResponse)) {
			waitingChunkDone.forEach((a) => {
				a.chunkDone = true;
			});
			this.onSmartCallDone(waitingChunkDone);
		}
		return true;
	}

	addRenderCall({ tagId }) {
		const auctionSlot = this.getAuctionSlot(tagId);
		if (auctionSlot.renderCalled) {
			return false;
		}
		auctionSlot.renderCalled = true;
		return true;
	}
}

module.exports = SmartAuction;
