const Utils = require('./utils');

// "HBM" Utility-functions that are not needed in HBA-only tags (put here to save space in HBA-only tags)
const HbmUtils = {
	createIframe(extraAttribs, style) {
		const iframe = document.createElement('iframe');
		const attribs = {
			FRAMEBORDER: 0,
			SCROLLING: 'no',
			MARGINHEIGHT: 0,
			MARGINWIDTH: 0,
			TOPMARGIN: 0,
			LEFTMARGIN: 0,
			ALLOWTRANSPARENCY: 'true',
			ALLOWFULLSCREEN: 'true',
			ALLOW: 'autoplay',
			...extraAttribs,
		};
		for (const key in attribs) {
			iframe.setAttribute(key, attribs[key]);
		}
		Utils.assign(iframe.style, style);
		return iframe;
	},

	// Creates an empty iframe for Prebid to render the winning bid
	renderBid({ auction, bid, adDiv, amazonBid }) {
		const {
			adId, width, height, mediaType,
		} = bid || {};
		const iframe = HbmUtils.createIframe({ width, height });
		if (mediaType === 'video') {
			iframe.style.display = 'none'; // Not automatically hidden by renderer
		}
		if (adDiv) {
			adDiv.appendChild(iframe);
			const ifrDoc = iframe.contentWindow.document;
			if (bid) {
				auction.pbjsCall('renderAd', ifrDoc, adId);
			} else if (amazonBid) {
				window.apstag?.renderImp?.(ifrDoc, amazonBid.amzniid);
			}
		}
	},
};

module.exports = HbmUtils;
